import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LibraryClient, LibraryModel } from '../../core/webapi';

@Injectable({ providedIn: 'root' })
export class LibraryResolver {
  library!: LibraryModel;

  constructor(private libraryClient: LibraryClient) {}

  resolve(): Observable<LibraryModel> {
    return this.libraryClient.getLibrary().pipe(tap((x) => (this.library = x)));
  }
}
