import { GroupModel, GroupType, Monitor, RegistrationListModel, ResponseStatus } from '../../../core/webapi';

const emailErrorMsg = 'De laatst verstuurde e-mail is niet aangekomen.';

export function getConsultantMailTooltip(row: RegistrationListModel, portal: Monitor, invitationQuestionnaire: boolean, isInvitationClosed: boolean) {
  const error =
    (row.hasSurveyEmailError && invitationQuestionnaire) || (row.hasRegistrationMailError && !invitationQuestionnaire) ? emailErrorMsg : '';

  return (
    (isInvitationClosed && invitationQuestionnaire
      ? 'De vragenlijst is niet geopend.'
      : row.consultantEmail === null && (portal === Monitor.Po || portal === Monitor.Vmbo)
        ? 'Er is nog geen consulent geselecteerd.'
        : !row.authorizationApproved
          ? 'Een e-mail naar de consulent is pas mogelijk na toestemming van de directie.'
          : row.hasReadingInformationSkills === null && invitationQuestionnaire
            ? 'Er is nog geen keuze gemaakt over informatievaardigheden.'
            : row.hasSchoolLibrary === null && invitationQuestionnaire
              ? 'Er is nog geen keuze gemaakt over de schoolbibliotheek.'
              : row.isSbo === null && portal === Monitor.Vmbo && invitationQuestionnaire
                ? 'Er is nog geen keuze gemaakt over speciaal voortgezet onderwijs.'
                : row.isSbo === null && portal !== Monitor.Vmbo && invitationQuestionnaire
                  ? 'Er is nog geen keuze gemaakt over speciaal (basis)onderwijs.'
                  : row.consultantSurveyStatus === ResponseStatus.Completed && invitationQuestionnaire
                    ? 'De consulent heeft de vragenlijst afgerond.'
                    : !row.isProjectOpen && !invitationQuestionnaire
                      ? 'Stuur een mail om de gegevens te registreren/bijwerken.'
                      : !row.isProjectOpen && invitationQuestionnaire
                        ? 'Het project is niet open, er is bijvoorbeeld geen (open) vragenlijst aan gekoppeld.'
                        : invitationQuestionnaire
                          ? 'Verstuur de uitnodiging voor de vragenlijst (opnieuw).'
                          : 'Verstuur de e-mail om de gegevens te registreren/bijwerken (opnieuw).') + error
  );
}

export function getSurveyStatusToolTip(status: ResponseStatus, role: string) {
  return status === ResponseStatus.Completed
    ? 'De ' + role + ' heeft de vragenlijst afgerond'
    : status === ResponseStatus.Started
      ? 'De ' + role + ' heeft de vragenlijst opgestart'
      : 'De ' + role + ' heeft de vragenlijst nog NIET opgestart';
}

export function getBookstartMailTooltip(row: RegistrationListModel, invitationQuestionnaire: boolean, isInvitationClosed: boolean) {
  const error =
    (row.hasSurveyEmailError && invitationQuestionnaire) || (row.hasRegistrationMailError && !invitationQuestionnaire) ? emailErrorMsg : '';

  return (
    (isInvitationClosed
      ? 'De vragenlijst is niet geopend.'
      : row.consultantSurveyStatus === ResponseStatus.Completed && invitationQuestionnaire
        ? 'De voorleescoördinator heeft de vragenlijst afgerond.'
        : row.bookStartConsultantEmail === null
          ? 'E-mail gegevens van de voorleescoördinator ontbreken.'
          : !row.isProjectOpen && !invitationQuestionnaire
            ? 'Stuur een mail om de gegevens te registreren/bijwerken.'
            : invitationQuestionnaire
              ? 'Verstuur de uitnodiging voor de vragenlijst (opnieuw).'
              : 'Verstuur de e-mail om de gegevens te registreren/bijwerken (opnieuw).') + error
  );
}

export function getAuthorizationTooltip(row: RegistrationListModel) {
  const error = row.hasDirectorEmailError ? emailErrorMsg : '';

  return (
    (row.directorEmail
      ? row.authorizationResponseDateTime
        ? row.authorizationApproved
          ? 'De toestemming voor deelname aan de Monitor de Bibliotheek is akkoord.'
          : 'De autorisatie heeft geen toestemming afgegeven.'
        : row.authorizationSendDateTime
          ? 'Verstuur een reminder voor toestemming voor deelname aan de Monitor de Bibliotheek.'
          : 'Verstuur een mail voor toestemming voor deelname aan de Monitor de Bibliotheek.'
      : 'De e-mail gegevens van de autorisatie ontbreken.') + error
  );
}

export function getPoVOStatusTooltip(row: RegistrationListModel) {
  return row.consultantSurveyStatus === ResponseStatus.Completed
    ? 'De consulent heeft de vragenlijst ingevuld.'
    : row.consultantSurveyStatus === ResponseStatus.Started
      ? 'De consulent heeft de vragenlijst opgestart.'
      : 'De consulent heeft de vragenlijst NIET opgestart.';
}

export function getGroupSendTooltip(row: GroupModel, isInvitationClosed: boolean, isSbo: boolean | null, hasSchoolLibrary: boolean | null) {
  return isInvitationClosed || !row.isProjectOpen
    ? 'De vragenlijst is niet geopend.'
    : isSbo === null || hasSchoolLibrary === null
      ? `Er is voor deze school nog geen keuze gemaakt voor 'Speciaal onderwijs' en/of 'Schoolbibliotheek'.`
      : row.lastSendDate
        ? 'De uitnodiging opnieuw versturen.'
        : 'De uitnodiging versturen.';
}

export function getDeleteTooltip(row: GroupModel) {
  return row.surveyFinishStudents > 0 || row.surveyStatus === ResponseStatus.Completed
    ? 'Verwijderen is niet meer toegestaan: er zijn al vragenlijsten ingevuld.'
    : 'Verwijderen. Eventueel verstuurde e-mails kunnen niet meer gebruikt worden om vragenlijsten in te vullen!';
}

export function getRegistrationDeleteTooltip(row: RegistrationListModel, monitor: Monitor) {
  if (monitor === Monitor.BookStart) {
    return row.consultantSurveyStatus > 0 ? 'Verwijderen is niet meer toegestaan: er zijn al vragenlijsten ingevuld.' : 'Verwijderen.';
  }
  return row.consultantSurveyStatus > 0
    ? 'Verwijderen is niet meer toegestaan: er zijn al vragenlijsten ingevuld.'
    : 'Verwijderen. Eventueel verstuurde e-mails kunnen niet meer gebruikt worden om vragenlijsten in te vullen!';
}

export function getRoleSurveyUser(monitor: Monitor.Po | Monitor.BookStart | Monitor.Vmbo, groupType: GroupType | null) {
  return monitor === Monitor.Po
    ? 'leerkracht'
    : monitor === Monitor.BookStart
      ? 'pedagogisch medewerker'
      : groupType === GroupType.Teacher
        ? 'docent'
        : 'leerlingcoördinator';
}

export function getRoleSurveyTooltip(monitor: Monitor.Po | Monitor.BookStart | Monitor.Vmbo) {
  return monitor === Monitor.Po
    ? 'Uitnodigen voor het invullen van de vragenlijst voor leerkrachten (en leerlingen groep 5-8).'
    : monitor === Monitor.BookStart
      ? 'Uitnodigen voor het invullen van de vragenlijst voor pedagogisch medewerker'
      : 'Uitnodigen voor het invullen van de vragenlijst voor docent / leerlingcoördinator';
}
